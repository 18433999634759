import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as Urql from 'urql'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import * as Icons from '../icons'
import * as Configuration from '../configuration'
import { Button } from '../button'
import { SportsbookDirect } from '../sportsbook-direct'
import { toImageUrl } from '../dynamic-image'
import { useMenuQueries } from '../use-menu-queries'
import { useSafeUpdateQuery } from '../use-safe-update-query'

const getCasinoCategories = `
  query CasinoCategories {
    catalog(key: "featured-landing-game") {
      ... on GetCatalogSuccess {
        categories {
          id
          key
          sections {
          id
          key
          games {
          id
          key
          liveCasinoGame
          backgroundUrl
    backgroundOverlayImageAlignment
    backgroundOverlayUrl
          provider
          title
          titleUrl
          }
          }
        }
      }
      ... on GetCatalogFailure {
        error
        errorCode
      }
    }
  }
`

const Scrollable = styled(Common.Box)`
  display: flex;
  overflow: scroll;
  margin-top: 16px;
  max-width: 100vw;
  gap: 8px;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }
`

export function LandingPage(props) {
  const i18n = useI18n()
  const { registerQuery } = useMenuQueries()
  const [response] = Urql.useQuery({ query: getCasinoCategories })
  const [navigation] = Urql.useQuery({ query: getCasinoCategories })
  const spinlirasChoiceSections =
    response.data?.catalog?.categories[0]?.sections?.map(section => {
      return section.key
    }) || []
  const imgproxyUrl = ReactRedux.useSelector(state =>
    Configuration.getImgproxyUrl(state.configuration)
  )
  const promoDetailsQuery = useSafeUpdateQuery({
    promotions: 'campaigns',
  })

  const [sectionSelected, setSectionSelected] = React.useState(null)

  const gamesBySection =
    response.data?.catalog?.categories[0].sections.find(
      el => el.key === sectionSelected
    )?.games || []

  React.useEffect(() => {
    if (spinlirasChoiceSections[0]) {
      setSectionSelected(spinlirasChoiceSections[0])
    }
  }, [spinlirasChoiceSections[0]])

  if (response.fetching || navigation.fetching) {
    return (
      <Common.Box
        alignSelf="center"
        color="g-text"
        display="flex"
        flexGrow="1"
        fontSize={['48px', '64px']}
        justifyContent="center"
        pt={3}
      >
        <Icons.Spinner />
      </Common.Box>
    )
  }

  return (
    <Common.Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth="1440px"
      mx="auto"
      p={['5px', '32px']}
    >
      <Common.Box
        color="#FAFCFF"
        display="flex"
        fontFamily="Inter"
        flexDirection="column"
        borderRadius="24px"
        backgroundImage={`url(${i18n.translate('landing-page.background')})`}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        height={['206px', '620px']}
        p={['12px', '72px']}
      >
        <Common.Box
          color="#0094FF"
          fontSize={['14px', '64px']}
          fontWeight="700"
          lineHeight={['16px', '77px']}
          pb="10px"
        >
          {i18n.translate('landing-page.welcome-offer.text-highlighted', {
            bonusPercentage: props.bonusPercentage,
            maxBonusAmount: props.maxBonusAmount,
            maxFeatureTriggers: props.maxFeatureTriggers,
          })}
        </Common.Box>
        <Common.Box
          fontSize={['21px', '54px']}
          lineHeight={['25px', '65px']}
          fontWeight={['600', '700']}
          maxWidth={['220px', '440px']}
          pb="38px"
        >
          {i18n.translate('landing-page.welcome-offer.text', {
            bonusPercentage: props.bonusPercentage,
            maxBonusAmount: props.maxBonusAmount,
            maxFeatureTriggers: props.maxFeatureTriggers,
          })}
        </Common.Box>
        <Common.Box width={['150px', '200px']} textAlign="center">
          <Button variant="primary" stretch>
            <ReactRouter.Link to={`?${registerQuery}`}>
              {i18n.translate('landing-page.cta')}
            </ReactRouter.Link>
          </Button>
          <Common.Link to={`?${promoDetailsQuery}`}>
            <Common.Box
              fontSize="15px"
              fontWeight="400"
              lineHeight="22px"
              pt="14px"
            >
              {i18n.translate('landing-page.welcome-offer.details')}
            </Common.Box>
          </Common.Link>
        </Common.Box>
      </Common.Box>
      {gamesBySection.length > 0 && (
        <Common.Box mt="37px" pb="30px" display={['none', 'block']}>
          <Common.Box
            color="#FAFCFF"
            fontSize="36px"
            fontWeight="700"
            mb="16px"
          >
            {i18n.translate('landing-page.featured-games.title')}
          </Common.Box>
          <Common.Box
            position="relative"
            width="100%"
            height="calc((100vw - 375px - 32px - 32px)*3/5)"
          >
            <Common.Box
              display={['none', null, 'flex']}
              position="absolute"
              right="0px"
              top="0px"
              color="white"
              fontWeight="600"
              lineHeight="26px"
              width="50%"
              maxWidth="400px"
              justifyContent="space-between"
            >
              <>
                {spinlirasChoiceSections.slice(0, 2).map(category => (
                  <Common.Box
                    key={category}
                    display="flex"
                    alignItems="flex-start"
                    onClick={() => setSectionSelected(category)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Common.Box
                      as="img"
                      pr="15px"
                      fontSize="15px"
                      src={i18n.translate(
                        `landing-page.featured-games.${category}.icon`
                      )}
                    />
                    <Common.Box
                      fontSize="18px"
                      pb="10px"
                      borderBottom={
                        sectionSelected === category
                          ? '3px solid #0094FF'
                          : 'none'
                      }
                    >
                      {i18n.translate(
                        `landing-page.featured-games.${category}`
                      )}
                    </Common.Box>
                  </Common.Box>
                ))}
                <Icons.Dots />
              </>
            </Common.Box>
            <Common.Box
              position="absolute"
              top="0px"
              left="0px"
              width="50.5%"
              height="calc(((100vw - 375px - 32px - 32px) / 2)*4/5)"
              minHeight="300px"
              backgroundImage={`url(
                ${toImageUrl(imgproxyUrl, gamesBySection[0]?.backgroundUrl)})`}
              backgroundPosition="center"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              borderTopLeftRadius="70px"
              overflowX="hidden"
            >
              <Common.Box position="relative" width="100%" height="100%">
                <Common.Box
                  position="absolute"
                  display="flex"
                  flexDirection="column"
                  top="5%"
                  left="0px"
                  right="0px"
                >
                  <Common.Box
                    backgroundColor="#0094FF"
                    px="22px"
                    mb="10px"
                    width="fit-content"
                    color="#FAFCFF"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="35px"
                    borderTopRightRadius="30px"
                    borderBottomRightRadius="30px"
                    textAlign="right"
                  >
                    {gamesBySection[0]?.provider}
                  </Common.Box>
                  <Common.Box
                    textAlign="center"
                    width="55%"
                    color="#FAFCFF"
                    fontSize="36px"
                    fontWeight="700"
                    background="linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)"
                    opacity="0.6"
                    lineHeight="52px"
                    px="22px"
                  >
                    {gamesBySection[0]?.title}
                  </Common.Box>
                </Common.Box>
                <Common.Box
                  width="110px"
                  position="absolute"
                  bottom="15%"
                  left="15%"
                >
                  <Button>
                    <ReactRouter.Link
                      to={`/casino/games/${gamesBySection[0]?.id}`}
                    >
                      {i18n.translate('landing-page.button.play-now')}
                    </ReactRouter.Link>
                  </Button>
                </Common.Box>
              </Common.Box>
            </Common.Box>
            <Common.Box
              position="absolute"
              display={['none', 'block']}
              bottom="0px"
              right="0px"
              width="50.5%"
              height="calc(((100vw - 375px - 32px - 32px) / 2)*4/5)"
              minHeight="300px"
              borderBottomRightRadius="70px"
              overflow="hidden"
              backgroundImage={`url(
                ${toImageUrl(imgproxyUrl, gamesBySection[1]?.backgroundUrl)})`}
              backgroundPosition="center"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
            >
              <Common.Box position="relative" width="100%" height="100%">
                <Common.Box
                  position="absolute"
                  display="flex"
                  flexDirection="column"
                  top="10%"
                  left="16px"
                  right="0px"
                >
                  <Common.Box
                    backgroundColor="#0094FF"
                    px="22px"
                    mb="10px"
                    width="fit-content"
                    color="#FAFCFF"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="35px"
                    borderTopRightRadius="30px"
                    borderBottomRightRadius="30px"
                    textAlign="right"
                  >
                    {gamesBySection[1]?.provider}
                  </Common.Box>
                  <Common.Box
                    textAlign="center"
                    width="55%"
                    color="#FAFCFF"
                    fontSize="36px"
                    fontWeight="700"
                    background="linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)"
                    opacity="0.6"
                    lineHeight="52px"
                    px="22px"
                  >
                    {gamesBySection[1]?.title}
                  </Common.Box>
                </Common.Box>
                <Common.Box
                  width="110px"
                  position="absolute"
                  bottom="15%"
                  left="15%"
                >
                  <Button>
                    <ReactRouter.Link
                      to={`/casino/games/${gamesBySection[1]?.id}`}
                    >
                      {i18n.translate('landing-page.button.play-now')}
                    </ReactRouter.Link>
                  </Button>
                </Common.Box>
              </Common.Box>
            </Common.Box>
          </Common.Box>
        </Common.Box>
      )}
      <Scrollable display={['flex', 'none']}>
        {gamesBySection.map((game, index) => (
          <Common.Box
            key={game.id}
            as={ReactRouter.Link}
            to={`/casino/games/${gamesBySection[index]?.id}`}
            position="relative"
            height="240px"
            minWidth="100%"
            backgroundImage={`url(
              ${toImageUrl(
                imgproxyUrl,
                gamesBySection[index]?.backgroundOverlayUrl
              )}),url(
              ${toImageUrl(imgproxyUrl, gamesBySection[index]?.titleUrl)}), url(
              ${toImageUrl(
                imgproxyUrl,
                gamesBySection[index]?.backgroundUrl
              )})`}
            backgroundPosition="center, center 90%, center"
            backgroundSize="70% , 50% ,cover"
            backgroundRepeat="no-repeat"
          >
            <Common.Box
              position="absolute"
              display="flex"
              flexDirection="column"
              top="12%"
              left="0px"
              right="0px"
            >
              <Common.Box
                backgroundColor="#0094FF"
                px="22px"
                mb="10px"
                width="fit-content"
                color="#000000"
                fontSize="24px"
                fontWeight="700"
                lineHeight="35px"
                borderTopRightRadius="30px"
                borderBottomRightRadius="30px"
                textAlign="right"
              >
                {gamesBySection[0]?.provider}
              </Common.Box>
            </Common.Box>
          </Common.Box>
        ))}
      </Scrollable>
      <Common.Box mt={['15px', '37px']} pb={['15px', '30px']}>
        <Common.Box
          color="#FAFCFF"
          fontSize="36px"
          fontWeight="700"
          mb="16px"
          display={['none', 'block']}
        >
          {i18n.translate('landing-page.sport.title')}
        </Common.Box>
        <SportsbookDirect
          widgetName="promo"
          widgetPlaceholder="operator_page1"
          minFrameHeight="205px"
        />
      </Common.Box>
      <Common.Box mt="37px" pb="30px">
        <Common.Box
          color="#FAFCFF"
          fontSize="36px"
          fontWeight="700"
          mb="16px"
          display={['none', 'block']}
        >
          {i18n.translate('landing-page.game-cetegories.title')}
        </Common.Box>
        <Common.Box
          display="grid"
          gridTemplateColumns={['1fr 1fr', 'repeat(4, 458px)']}
          gridTemplateRows={['1fr 1fr', '1fr']}
          width="100%"
          overflow={['unset', 'scroll']}
          fontFamily="Roboto"
          gridGap={['16px', '28px']}
        >
          <Common.Box
            as={ReactRouter.Link}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="start"
            borderRadius="25px"
            background={`linear-gradient(180deg, rgba(28, 33, 38, 0.00) 23.26%, #1C2126 100%), url(${i18n.translate(
              'landing-page.game-cetegories.casino.background'
            )})`}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            px={['16px', '45px']}
            py={['22px', '45px']}
            height={['182px', '430px']}
            to="/live-casino"
            style={{ cursor: 'pointer' }}
          >
            <Common.Box
              as="img"
              height={['28px', '50px']}
              width="auto"
              src={i18n.translate(`landing-page.game-cetegories.casino.icon`)}
            />
            <Common.Box mt="50px">
              <Common.Flex alignItems="flex-start">
                <Common.Text
                  fontSize={['24px', '36px']}
                  lineHeight={['29px', '42px']}
                  fontWeight="700"
                >
                  {i18n.translate(
                    `landing-page.game-cetegories.type.casino-game`
                  )}
                </Common.Text>
                <Common.Box
                  backgroundColor="#CE2135"
                  borderRadius="4px"
                  p="3px"
                  ml="18px"
                  mt="6px"
                  fontSize="12px"
                  style={{ transform: 'skew(-12deg)' }}
                >
                  <Common.Text fontWeight="800">
                    {i18n.translate(`landing-page.game-cetegories.live-tag`)}
                  </Common.Text>
                </Common.Box>
              </Common.Flex>
              <Common.Text
                fontSize={['24px', '36px']}
                lineHeight={['29px', '42px']}
                fontWeight="400"
              >
                {i18n.translate(`landing-page.game-cetegories.game`)}
              </Common.Text>
            </Common.Box>
            <Common.Box
              pl="30px"
              fontSize="15px"
              fontWeight="600"
              lineHeight="22px"
              display={['none', 'block']}
            >
              {i18n.translate(`landing-page.game-cetegories.bottom-text`)}
            </Common.Box>
          </Common.Box>
          <Common.Box
            as={ReactRouter.Link}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="start"
            borderRadius="25px"
            background={`linear-gradient(180deg, rgba(28, 33, 38, 0.00) 23.26%, #1C2126 100%), url(${i18n.translate(
              'landing-page.game-cetegories.sport.background'
            )})`}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            px={['16px', '45px']}
            py={['22px', '45px']}
            height={['182px', '430px']}
            to="/sports"
            style={{ cursor: 'pointer' }}
          >
            <Common.Box
              as="img"
              height={['28px', '50px']}
              width="auto"
              src={i18n.translate(`landing-page.game-cetegories.sport.icon`)}
            />
            <Common.Box mt="50px">
              <Common.Flex alignItems="flex-start">
                <Common.Text
                  fontSize={['24px', '36px']}
                  lineHeight={['29px', '42px']}
                  fontWeight="700"
                >
                  {i18n.translate(
                    `landing-page.game-cetegories.type.sport-game`
                  )}
                </Common.Text>
                <Common.Box
                  backgroundColor="#CE2135"
                  borderRadius="4px"
                  p="3px"
                  ml="18px"
                  mt="6px"
                  fontSize="12px"
                  style={{ transform: 'skew(-12deg)' }}
                >
                  <Common.Text fontWeight="800">
                    {i18n.translate(`landing-page.game-cetegories.live-tag`)}
                  </Common.Text>
                </Common.Box>
              </Common.Flex>
              <Common.Text
                fontSize={['24px', '36px']}
                lineHeight={['29px', '42px']}
                fontWeight="400"
              >
                {i18n.translate(`landing-page.game-cetegories.game`)}
              </Common.Text>
            </Common.Box>
            <Common.Box
              pl="30px"
              fontSize="15px"
              fontWeight="600"
              lineHeight="22px"
              display={['none', 'block']}
            >
              {i18n.translate(`landing-page.game-cetegories.bottom-text`)}
            </Common.Box>
          </Common.Box>
          <Common.Box
            as={ReactRouter.Link}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="start"
            borderRadius="25px"
            background={`linear-gradient(180deg, rgba(28, 33, 38, 0.00) 23.26%, #1C2126 100%), url(${i18n.translate(
              'landing-page.game-cetegories.poker.background'
            )})`}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            px={['16px', '45px']}
            py={['22px', '45px']}
            height={['182px', '430px']}
            to="/casino/17/CrashGames"
            style={{ cursor: 'pointer' }}
          >
            <Common.Box
              as="img"
              height={['28px', '50px']}
              width="auto"
              src={i18n.translate(`landing-page.game-cetegories.poker.icon`)}
            />
            <Common.Box mt="50px">
              <Common.Flex alignItems="flex-start">
                <Common.Text
                  fontSize={['24px', '36px']}
                  lineHeight={['29px', '42px']}
                  fontWeight="700"
                >
                  {i18n.translate(
                    `landing-page.game-cetegories.type.poker-game`
                  )}
                </Common.Text>
              </Common.Flex>
              <Common.Text
                fontSize={['24px', '36px']}
                lineHeight={['29px', '42px']}
                fontWeight="400"
              >
                {i18n.translate(`landing-page.game-cetegories.game`)}
              </Common.Text>
            </Common.Box>
            <Common.Box
              pl="30px"
              fontSize="15px"
              fontWeight="600"
              lineHeight="22px"
              display={['none', 'block']}
            >
              {i18n.translate(`landing-page.game-cetegories.bottom-text`)}
            </Common.Box>
          </Common.Box>
          <Common.Box
            as={ReactRouter.Link}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="start"
            height={['182px', '430px']}
            borderRadius="25px"
            background={`linear-gradient(180deg, rgba(28, 33, 38, 0.00) 23.26%, #1C2126 100%), url(${i18n.translate(
              'landing-page.game-cetegories.slot.background'
            )})`}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            px={['16px', '45px']}
            py={['22px', '45px']}
            to="/casino/16/Popular"
            style={{ cursor: 'pointer' }}
          >
            <Common.Box
              as="img"
              height={['28px', '50px']}
              width="auto"
              src={i18n.translate(`landing-page.game-cetegories.slot.icon`)}
            />
            <Common.Box mt="50px">
              <Common.Flex alignItems="flex-start">
                <Common.Text
                  fontSize={['24px', '36px']}
                  lineHeight={['29px', '42px']}
                  fontWeight="700"
                >
                  {i18n.translate(
                    `landing-page.game-cetegories.type.slot-game`
                  )}
                </Common.Text>
              </Common.Flex>
              <Common.Text
                fontSize={['24px', '36px']}
                lineHeight={['29px', '42px']}
                fontWeight="400"
              >
                {i18n.translate(`landing-page.game-cetegories.game`)}
              </Common.Text>
            </Common.Box>
            <Common.Box
              display={['none', 'block']}
              pl="30px"
              fontSize="15px"
              fontWeight="600"
              lineHeight="22px"
            >
              {i18n.translate(`landing-page.game-cetegories.bottom-text`)}
            </Common.Box>
          </Common.Box>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

LandingPage.propTypes = {
  bonusPercentage: PropTypes.number.isRequired,
  maxBonusAmount: PropTypes.number.isRequired,
  maxFeatureTriggers: PropTypes.number.isRequired,
}

// for @loadable/components
export default LandingPage
